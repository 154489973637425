.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}
.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}
.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}
.tim-row {
  padding-top: 60px;
}
.tim-row h3 {
  margin-top: 0;
}

.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.tim-typo .tim-note {
  bottom: 5px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}

.sidebar-background {
  background-image: url("../img/sidebar-1.jpg");
}

.page-header {
  background-image: url("../img/login.jpg");
  background-size: cover;
  background-position: top center;
}

.form-image {
  width: 120px;
  max-height: 160px;
  overflow: hidden;
  display: block;
}

.form-image img {
  width: 100%;
}

.show-sidebar {
  top: 0 !important;
  left: -100px !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  background-color: #282828 !important;
  display: block !important;
  content: "" !important;
  z-index: 9999 !important;
}

.toggled {
  margin-right: 210px;
  margin-top: 5px;
}

.visible {
  z-index: 26;
}

@media screen and (max-width:767px){
  .content {
    padding-right: 0 !important; 
    padding-left: 0 !important;
  }
  .container-fluid {
    padding-right: 0 !important; 
    padding-left: 0 !important;
  }
  .card .card-body{
    padding-right: 0 !important; 
    padding-left: 0 !important;
  }
}
